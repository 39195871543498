import { Injectable } from '@angular/core';
import { of as observableOf,  Observable } from 'rxjs';
import { PeriodsService } from './periods.service';
import { LocationsService } from './locations.service';
import { UserActive, LiveOrdersData } from '../data/live-orders';

@Injectable()
export class LiveOrdersService extends LiveOrdersData {

  private getRandom = (roundTo: number) => Math.round(Math.random() * roundTo);
  private generateLiveOrdersRandomData(date) {
    return {
      date,
      pagesVisitCount: this.getRandom(300),
      deltaUp: this.getRandom(1) % 2 === 0,
      newVisits: this.getRandom(100),
    };
  }

  data = {};

  constructor(private periods: PeriodsService) {
    super();
    this.data = {
      week: this.getDataWeek(),
      month: this.getDataMonth(),
      year: this.getDataYear(),
      location: this.getDataTables(),
      'Location 1': this.getDataTables(),
      'Location 2': this.getDataTables(),
      'Location 3': this.getDataTables(),
      cart: this.getCartItems(),
    };
  }

  private getCartItems(): UserActive[] {
    return this.periods.getCartItems().map((item) => {
      return this.generateLiveOrdersRandomData(item);
    });
  }


  private getDataLocation(): UserActive[] {
    return this.periods.getLocations().map((location) => {
      return this.generateLiveOrdersRandomData(location);
    });
  }

  private getDataTables(): UserActive[] {
    return this.periods.getTables().map((Table) => {
      return this.generateLiveOrdersRandomData(Table);
    });
  }

  private getDataWeek(): UserActive[] {
    return this.periods.getWeeks().map((week) => {
      return this.generateLiveOrdersRandomData(week);
    });
  }

  private getDataMonth(): UserActive[] {
    const currentDate = new Date();
    const days = currentDate.getDate();
    const month = this.periods.getMonths()[currentDate.getMonth()];

    return Array.from(Array(days)).map((_, index) => {
      const date = `${index + 1} ${month}`;

      return this.generateLiveOrdersRandomData(date);
    });
  }

  private getDataYear(): UserActive[] {
    return this.periods.getYears().map((year) => {
      return this.generateLiveOrdersRandomData(year);
    });
  }

  getLiveOrdersData(period: string): Observable<UserActive[]> {
    return observableOf(this.data[period]);
  }
  addLiveOrdersData(item:UserActive[]) {
   // this.data['cart'].push(this.generateLiveOrdersRandomData('Another one'));
    this.data['cart'].push(item);
   }
}
