import { Observable } from 'rxjs';

export interface UserActive {
  date: string;
  pagesVisitCount: number;
  deltaUp: boolean;
  newVisits: number;
}

export abstract class LiveOrdersData {
  abstract getLiveOrdersData(period: string): Observable<UserActive[]>;
  abstract addLiveOrdersData(item:UserActive[]); 

}
