import { Injectable } from '@angular/core';

@Injectable()
export class PeriodsService {
  getYears() {
    return [
      '2010', '2011', '2012',
      '2013', '2014', '2015',
      '2016', '2017', '2018',
    ];
  }

  getMonths() {
    return [
      'Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep',
      'Oct', 'Nov', 'Dec',
    ];
  }

/*
  getWeeks() {
    return [
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
      'Sun',
    ];
  }
*/
getItems() {
    return [
      'Item 1',
      'Item 2',
      'Item 3',
      'Item 4',
      'Item 5',
      'Item 6',
      'Item 7',
      'Item 8',
      'Item 9'
    ];
  }

getTables() {
    return [
      'Table 10',
      'Table 2',
      'Table 5',
      'Table 7',
      'Table 1',
      'Table 6',
    ];
  }

getCartItems() {
    return [
    ];
 }

  
getWeeks() {
    return [
      'Location 1',
      'Location 2',
      'Location 3',
      'Location 4',
      'Location 5',
      'Location 6',
    ];
  }

    getLocations() {
    return [
      'Location 1',
      'Location 2',
      'Location 3',
    ];
  }

}
