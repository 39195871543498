import { Injectable } from '@angular/core';
import { CanActivate,CanActivateChild, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: NbAuthService, private router: Router) {


  }

  canActivate() {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          }else{

           this.authService.getToken().subscribe((value) => {
                 let data:any=value;
                 let   values=data.token.split(',');
                     //token 0 , role 1, username 2
                      if(values[1]!='super'){
                          this.router.navigate(['auth/login']);
                      }

             // this.authService.refreshToken('email', { token: value['token'] }).subscribe((token) => {
             //});
            });
          }
        }),
      );
  }

  canActivateChild() {
     return this.canActivate();
  }

}


