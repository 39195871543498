import { Injectable } from '@angular/core';
import { CategoriesData } from '../data/categories';

@Injectable()
export class CategoriesService extends CategoriesData {
  data = [
  {
    id: 1,
    firstName: 'Category 1',
    lastName: 'Category Desc 1',
    username: 10,
    email: 1,
    age: 'Type 1',
  }, 
{
    id: 2,
    firstName: 'Category 2',
    lastName: 'Category Desc 2',
    username: 10,
    email: 2,
    age: 'Type 2',
  }, 
{
    id: 2,
    firstName: 'Category 2',
    lastName: 'Category Desc 2',
    username: 10,
    email: 2,
    age: 'Type 2',
  }, 
{
    id: 3,
    firstName: 'Category 3',
    lastName: 'Category Desc 3',
    username: 10,
    email: 3,
    age: 'Type 3',
  }, 
{
    id: 4,
    firstName: 'Category 4',
    lastName: 'Category Desc 4',
    username: 10,
    email: 4,
    age: 'Type 4',
  }, 
{
    id: 5,
    firstName: 'Category 5',
    lastName: 'Category Desc 5',
    username: 10,
    email: 5,
    age: 'Type 5',
  }, 
{
    id: 6,
    firstName: 'Category 6',
    lastName: 'Category Desc 6',
    username: 10,
    email: 6,
    age: 'Type 6',
  }, 


  ];

  getData() {
    return this.data;
  }
}
